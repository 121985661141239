<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">站点管理</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
        <el-button
          icon="el-icon-plus"
          type="primary"
          class="s-btn-add a-ml-24"
          @click="handlerAddStation"
        >
          站点
        </el-button>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form
        id="leSearch"
        :can-pack-up="false"
        @reset="handlerRest"
        @search="handlerSearch"
      >
        <le-input-icon-search v-model="pageParam.params.searchKey" label="站点名称" />
        <le-select-local-search
          v-model="pageParam.params.isSearchAgency"
          label="站点类型"
          :clearble="false"
          :options="stationTypeOptions"
        />
        <le-select-local-search
          v-model="pageParam.params.status"
          label="站点状态"
          :options="stationStatusOptions"
        />
        <le-company-under-select
          v-model="pageParam.params.searchCompanyId"
          label="所属商户"
        ></le-company-under-select>
      </le-search-form>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
        >
          <el-table-column prop="name" label="站点名称" width="150"></el-table-column>
          <el-table-column prop="companyName" label="所属商户"></el-table-column>
          <el-table-column prop="address" label="地址" width="150"></el-table-column>
          <el-table-column prop="statusText" label="站点状态"></el-table-column>
          <el-table-column prop="applyDate" label="建站日期"></el-table-column>
          <el-table-column prop="onlineDeviceNum" label="在线设备数"></el-table-column>
          <el-table-column prop="totalDeviceNum" label="设备总数"></el-table-column>
          <el-table-column prop="usingDeviceNum" label="使用中"></el-table-column>
          <el-table-column prop="usingDeviceSlotNum" label="使用中(插槽)"></el-table-column>
          <el-table-column label="今日营业额">
            <template slot-scope="{ row }">
              <span>{{ util.numFormat(row.todayOrderAmount) }}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="todayOrderNum" label="今日订单量"></el-table-column>
          <el-table-column label="昨日营业额">
            <template slot-scope="{ row }">
              <span>{{ util.numFormat(row.yesterdayOrderAmount) }}元</span>
            </template>
          </el-table-column>
          <el-table-column prop="yesterdayOrderNum" label="昨日订单量"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <div class="a-flex-rcc">
                <el-tooltip class="item" effect="dark" content="站点详细" placement="top">
                  <div @click="handlerGoDetail(row)">
                    <img src="../../assets/icon/option-detail.png" class="a-wh-16" />
                  </div>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import util from "../../utils/util.js";

export default {
  name: "StationList",

  data() {
    return {
      util: util,

      stationTypeOptions: [
        {
          value: "2",
          label: "全部",
        },
        {
          value: "0",
          label: "自营",
        },
        {
          value: "1",
          label: "参与收益",
        },
      ],
      stationStatusOptions: [
        {
          value: "2",
          label: "运营中",
        },
        {
          value: "0",
          label: "申报中",
        },
        {
          value: "1",
          label: "建设中",
        },
        {
          value: "3",
          label: "已退役",
        },
      ],
      activeTab: "2",
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getStationInfoList,
        method: "post",
        params: {
          searchKey: "",
          status: "",
          isSearchAgency: "2",
          searchCompanyId: "",
          districtCode: "",
          cityCode: "",
          provinceCode: "",
        },
        freshCtrl: 1,
      },
    };
  },

  methods: {
    setTableData(data) {
      this.tableData = data;
    },

    handlerRest() {
      this.pageParam.params = {
        stationName: "",
        status: "",
        isSearchAgency: "",
      };
    },

    handlerSearch() {
      this.pageParam.freshCtrl++;
    },

    handlerAddStation() {
      this.$router.push({
        path: "/station/station-add",
      });
    },

    handlerGoDetail(item) {
      this.$router.push({
        path: "/station/station-detail",
        query: {
          stationId: item.id,
        },
      });
    },
  },
};
</script>
